'use strict';
/**
 * The job list
 * @param {Object} $scope - the local scope object
 * @param {Object} $state - the state object
 * @param {Object} DataManager - the DataManager API
 * @param {Object} Notification - The Notification util
 */
angular.module('cpformplastApp.jobs')
    .controller('machineScheduleController', function($scope, $state, $modal, DataManager, Notification, Util) {

  $scope.dayInWeeks = [0,1,2,3,4,5,6]
  $scope.startingHours = [5,6,7,8,9,10,11];
  $scope.endingHours = [13,14,15,16,17,18,19,20,21,22,23];

  $scope.machines = [];
  $scope.allMachinesPerDay = {};
  $scope.date = new Date();
  $scope.weekDates = Util.getWeekArray($scope.date);

  $scope.backToDate = function() {
    $scope.date = new Date();
    $scope.weekDates = Util.getWeekArray($scope.date);
    $scope.fetchMachines();
  };

  $scope.manageSchedules = function(item) {
    var modalInstance = $modal.open({
      animation: true,
      templateUrl: '../../components/modal/machine/machine-schedule-modal.html',
      controller: 'MachineScheduleModal',
      resolve:{
        machines: function () {
          return $scope.machines;
        }
      }
    });
    modalInstance.result.then(null, function() {
      $scope.fetchMachines();
    });
  };

  $scope.decrementWeek = function() {
    $scope.date.setDate($scope.date.getDate() - 7);
    $scope.weekDates = Util.getWeekArray($scope.date);
    $scope.fetchMachines();
  };

  $scope.incrementWeek = function() {
    $scope.date.setDate($scope.date.getDate() + 7);
    $scope.weekDates = Util.getWeekArray($scope.date);
    $scope.fetchMachines();
  };

  $scope.getDayString = function(date) {
    return Util.getShortDayString(date) + ' (' + date.getDate() + ' ' + Util.getShortMonthString(date) + ')';
  };

  $scope.getShortDateString = function(date) {
    return date.getDate() + ' ' + Util.getShortMonthString(date);
  };

  $scope.fetchMachines = function() {
    const start = new Date($scope.weekDates[0]);
    const end = new Date($scope.weekDates[6]);
    start.setHours(0, 0, 0, 0);
    end.setHours(1, 0, 0, 0);

    DataManager.fetchMachines(start, end)
    .then(machines => {
      $scope.machines = machines;
      $scope.allMachinesPerDay = createAllMachinesPerDay(machines);
    })
    .catch(err => {
      console.log(err);
      Notification.error("Un problème est survenu lors du chargement de l'horaire");
    });
  };

  const createAllMachinesPerDay = (machines) => {
    let all = {};

    for (const day in $scope.dayInWeeks) {
      all[day] = {
        areOn: machines.every(machine => machine.schedule[day].isOn),
        arePassed: machines.some(machine => machine.schedule[day].isPassed()),
        save: () => machines.map(machine => {
          if (machine.schedule[day].isOn !== $scope.allMachinesPerDay[day].areOn) {
            machine.schedule[day].isOn = $scope.allMachinesPerDay[day].areOn
            machine.schedule[day].save();
          }
        }),
      };
    }
    return all;
  };
  
  $scope.saveMachineSchedule = (machine, day) => {
    // Force-update allMachinesPerDay
    $scope.allMachinesPerDay[day].areOn = $scope.machines.every(machine => machine.schedule[day].isOn);
    machine.schedule[day].save();
  };
  
  $scope.getMachineIndex = (machine) => {
   return $scope.machines.findIndex(m => m.number === machine.number);
  }
  
  /**
   * Initialisation function
   */

  $scope.init = function() {
    $scope.fetchMachines();
  };

  $scope.init();
});
